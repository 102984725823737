export const formatEntityData = entityData => {
    const { addresses, email, ...rest } = entityData

    const addressesWithoutId = addresses.map(address => {
        delete address._id

        return address
    })

    return {
        ...rest,
        addresses: addressesWithoutId,
    }
}
