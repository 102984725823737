<template>
  <b-card>
    <b-form>
      <b-row>
        <b-col cols="12">
          <div class="d-flex align-items-center mb-2">
            <feather-icon
              icon="FileTextIcon"
              size="18"
            />
            <h4 class="mb-0 ml-75">
              Commercial Information
            </h4>
          </div>
        </b-col>
        <!-- company name -->
        <b-col cols="12">
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Company Name"
                label-for="account-companyName"
              >
                <b-form-input
                  v-model="companyName"
                  name="companyName"
                  placeholder="Enter Company Name"
                  :class="{'cursor-not-allowed': isNotEntityAdmin}"
                  :disabled="isNotEntityAdmin"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <!-- trade license -->
        <b-col md="6">
          <b-form-group
            label="Trade License"
            label-for="account-trade-license"
          >
            <b-form-file
              id="account-trade-license"
              v-model="tradeLicenseFile"
              :class="{'disabled-file-input': isNotEntityAdmin}"
              :disabled="isNotEntityAdmin"
              :placeholder="
                documentData.tradeLicense
                  ? 'Trade License Uploaded'
                  : 'Choose a file or drop it here...'
              "
              drop-placeholder="Drop file here..."
            />
          </b-form-group>
        </b-col>
        <!-- vat certificate -->
        <b-col md="6">
          <b-form-group
            label="Vat Certificate"
            label-for="account-vat-certificate"
          >
            <b-form-file
              id="account-vat-certificate"
              v-model="vatCertificateFile"
              :class="{'disabled-file-input': isNotEntityAdmin}"
              :disabled="isNotEntityAdmin"
              :placeholder="
                documentData.vatCertificate
                  ? 'Vat Certificate Uploaded'
                  : 'Choose a file or drop it here...'
              "
              drop-placeholder="Drop file here..."
            />
          </b-form-group>
        </b-col>
        <!-- trade license number -->
        <b-col md="6">
          <b-form-group
            label="Trade License Number"
            label-for="tradeLicenseNumber"
          >
            <b-form-input
              v-model="tradeLicenseNumber"
              name="tradeLicenseNumber"
              :class="{'cursor-not-allowed': isNotEntityAdmin}"
              :disabled="isNotEntityAdmin"
              placeholder="Trade License Number"
            />
          </b-form-group>
        </b-col>
        <!-- vat certificate number -->
        <b-col md="6">
          <b-form-group
            label="Vat Certificate Number"
            label-for="vatCertificateNumber"
          >
            <b-form-input
              v-model="vatCertificateNumber"
              name="vatCertificateNumber"
              :class="{'cursor-not-allowed': isNotEntityAdmin}"
              :disabled="isNotEntityAdmin"
              placeholder="Vat Certificate Number"
            />
          </b-form-group>
        </b-col>
        <template v-if="isRetailer">
          <!-- Trade license expiry date -->
          <b-col md="6">
            <b-form-group
              label="Trade license expiry date"
              label-for="tradeLicenseExpiryDate"
              :class="{'position-relative': true, 'disabled-date-input': isNotEntityAdmin}"
            >
              <flat-pickr
                ref="tradeLicenseExpiryDate"
                v-model="tradeLicenseExpiryDate"
                class="form-control"
                name="tradeLicenseExpiryDate"
                :config="{ dateFormat: KP_DATE_FORMAT.CONFIG }"
                :disabled="isNotEntityAdmin"
                placeholder="Trade License Expiry Date"
              />
              <feather-icon
                icon="CalendarIcon"
                class="cal-icon"
                size="20"
                @click="$refs.tradeLicenseExpiryDate.$el._flatpickr.toggle()"
              />
            </b-form-group>
          </b-col>
          <!-- Date of Incorporation -->
          <b-col md="6">
            <b-form-group
              label="Date of Incorporation"
              label-for="dateOfIncorporation"
              :class="{'position-relative': true, 'disabled-date-input': isNotEntityAdmin}"
            >
              <flat-pickr
                ref="dateOfIncorporation"
                v-model="dateOfIncorporation"
                class="form-control"
                name="dateOfIncorporation"
                placeholder="Date of Incorporation"
                :disabled="isNotEntityAdmin"
                :config="{ dateFormat: KP_DATE_FORMAT.CONFIG }"
              />
              <feather-icon
                icon="CalendarIcon"
                class="cal-icon"
                size="20"
                @click="$refs.dateOfIncorporation.$el._flatpickr.toggle()"
              />
            </b-form-group>
          </b-col>
        </template>
        <!-- vat certificate -->
        <b-col md="6">
          <b-form-group
            label="Company Profile"
            label-for="account-company-profile"
          >
            <b-form-file
              id="account-company-profile"
              v-model="companyProfileFile"
              :class="{'disabled-file-input': isNotEntityAdmin}"
              :disabled="isNotEntityAdmin"
              :placeholder="
                documentData.companyProfile
                  ? 'Company Profile Uploaded'
                  : 'Choose a file or drop it here...'
              "
              drop-placeholder="Drop file here..."
            />
          </b-form-group>
        </b-col>
        <!-- vat certificate -->
        <b-col md="6">
          <b-form-group
            label="Others"
            label-for="account-others"
          >
            <b-form-file
              id="account-others"
              v-model="othersFile"
              :class="{'disabled-file-input': isNotEntityAdmin}"
              :disabled="isNotEntityAdmin"
              :placeholder="
                documentData.others
                  ? 'Others File Uploaded'
                  : 'Choose a file or drop it here...'
              "
              drop-placeholder="Drop file here..."
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <hr class="my-2">
        </b-col>
        <!-- buttons -->
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
            :class="{'cursor-not-allowed': isNotEntityAdmin}"
            :disabled="loading || isNotEntityAdmin"
            @click.prevent="uploadDocuments"
          >
            {{ loading ? 'Saving...' : 'Save documents' }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            class="mt-1 ml-25"
            variant="outline-secondary"
            :class="{'cursor-not-allowed': isNotEntityAdmin}"
            :disabled="isNotEntityAdmin"
            @click.prevent="resetForm"
          >
            Cancel
          </b-button>
        </b-col>
        <!--/ buttons -->
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormFile,
  BRow,
  BCol,
  BCard,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { apiToastWarning, apiToastSuccess } from '@/@core/utils/toast'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import analytics from '@/@core/utils/analytics'
import constants from '@/constants'
import flatPickr from 'vue-flatpickr-component'
import { constants as c } from '@kingpin-global/kingpin-utils-frontend'

import store from '@/store'
import { UPLOAD_DOCUMENTS } from '@/store/modules/common.module'
import { UPDATE_ENTITY } from '@/store/modules/auth.module'
import { formatEntityData } from '@/utils/formatEntityData'
import { convertDateStringToDateObj } from '@/common-utils'

const { FILE_TYPES, KP_DATE_FORMAT } = c

export default {
  name: 'AccountSettingDocument',
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    BRow,
    BCol,
    BCard,
    FeatherIcon,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  props: {
    documentData: {
      type: Object,
      default() {
        return {
          companyName: null,
          tradeLicense: null,
          vatCertificate: null,
          tradeLicenseNumber: null,
          vatCertificateNumber: null,
          companyProfile: null,
          others: null,
          tradeLicenseExpiryDate: null,
          dateOfIncorporation: null,
        }
      },
    },
  },
  data() {
    return {
      loading: false,
      documentLocal: this.buildDefault(),
      companyName: this.documentData.companyName,
      tradeLicenseFile: null,
      tradeLicenseNumber: this.documentData.tradeLicenseNumber,
      vatCertificateFile: null,
      vatCertificateNumber: this.documentData.vatCertificateNumber,
      companyProfileFile: null,
      othersFile: null,
      tradeLicenseExpiryDate: this.documentData.tradeLicenseExpiryDate,
      dateOfIncorporation: this.documentData.dateOfIncorporation,
      KP_DATE_FORMAT,
    }
  },
  computed: {
    isRetailer() {
      return store.getters.isRetailer
    },
    isNotEntityAdmin() {
      return !store.getters.isEntityAdmin
    },
  },
  emits: ['update-document'],
  methods: {
    buildDefault() {
      let documentLocal = JSON.parse(JSON.stringify(this.documentData))
      if (!documentLocal) {
        documentLocal = {
          companyName: null,
          tradeLicense: null,
          vatCertificate: null,
          tradeLicenseNumber: null,
          vatCertificateNumber: null,
          companyProfile: null,
          others: null,
          tradeLicenseExpiryDate: null,
          dateOfIncorporation: null,
        }
      }
      return documentLocal
    },
    resetForm() {
      this.documentLocal = this.buildDefault()
      this.companyName = this.documentData.companyName
      this.tradeLicenseFile = null
      this.tradeLicenseNumber = this.documentData.tradeLicenseNumber
      this.vatCertificateFile = null
      this.vatCertificateNumber = this.documentData.vatCertificateNumber
      this.companyProfileFile = null
      this.othersFile = null
      this.tradeLicenseExpiryDate = this.documentData.tradeLicenseExpiryDate
      this.dateOfIncorporation = this.documentData.dateOfIncorporation
    },
    async uploadTradeLicense() {
      if (this.tradeLicenseFile instanceof File) {
        this.documentLocal.tradeLicense = await store.dispatch(UPLOAD_DOCUMENTS, { fileType: FILE_TYPES.TRADE_LICENSE, file: this.tradeLicenseFile })
        return true
      }
      return false
    },
    async uploadVatCertificate() {
      if (this.vatCertificateFile instanceof File) {
        this.documentLocal.vatCertificate = await store.dispatch(UPLOAD_DOCUMENTS, { fileType: FILE_TYPES.VAT_CERTIFICATE, file: this.vatCertificateFile })
        return true
      }
      return false
    },
    async uploadCompanyProfile() {
      if (this.companyProfileFile instanceof File) {
        this.documentLocal.companyProfile = await store.dispatch(UPLOAD_DOCUMENTS, { fileType: FILE_TYPES.COMPANY_PROFILE, file: this.companyProfileFile })
        return true
      }
      return false
    },
    async uploadOthers() {
      if (this.othersFile instanceof File) {
        this.documentLocal.others = await store.dispatch(UPLOAD_DOCUMENTS, { fileType: FILE_TYPES.OTHERS, file: this.othersFile })
        return true
      }
      return false
    },
    prepareDocumentData() {
      this.documentLocal.companyName = this.companyName
      this.documentLocal.tradeLicenseNumber = this.tradeLicenseNumber
      this.documentLocal.vatCertificateNumber = this.vatCertificateNumber
      if (this.tradeLicenseExpiryDate) {
        this.documentLocal.tradeLicenseExpiryDate = convertDateStringToDateObj(this.tradeLicenseExpiryDate)
      }
      if (this.dateOfIncorporation) {
        this.documentLocal.dateOfIncorporation = convertDateStringToDateObj(this.dateOfIncorporation)
      }
    },
    async uploadAllFiles() {
      // upload trader first
      const hasTradeLicense = await this.uploadTradeLicense()
      // upload vat cert
      const hasVatCertificate = await this.uploadVatCertificate()
      // upload company profile
      const hasCompanyProfile = await this.uploadCompanyProfile()
      // upload others
      const hasOthers = await this.uploadOthers()

      return {
        hasTradeLicense,
        hasVatCertificate,
        hasCompanyProfile,
        hasOthers,
      }
    },
    async getMappedPayload() {
      // upload trader first
      const hasTradeLicense = await this.uploadTradeLicense()
      // upload vat cert
      const hasVatCertificate = await this.uploadVatCertificate()
      // upload company profile
      const hasCompanyProfile = await this.uploadCompanyProfile()
      // upload others
      const hasOthers = await this.uploadOthers()

      const payload = this.documentLocal

      const fieldsToRemove = [
        { field: 'tradeLicense', condition: !hasTradeLicense && !payload.tradeLicense },
        { field: 'vatCertificate', condition: !hasVatCertificate && !payload.vatCertificate },
        { field: 'companyProfile', condition: !hasCompanyProfile && !payload.companyProfile },
        { field: 'others', condition: !hasOthers && !payload.others },
      ]

      fieldsToRemove.forEach(({ field, condition }) => {
        if (condition) delete payload[field]
      })

      return payload
    },
    async uploadDocuments() {
      this.loading = true
      this.prepareDocumentData()
      const entityData = formatEntityData({ ...store.getters.entityData })
      const payload = await this.getMappedPayload()
      const updatedPayload = {
        ...entityData,
        ...payload,
      }
      if (Object.keys(payload).length > 0) {
        // save documents
        store.dispatch(UPDATE_ENTITY, updatedPayload)
          .then(res => {
            this.$emit('update-document', this.documentLocal)
            apiToastSuccess(res.data.message || 'Profile updated successfully.')
            analytics.track(
              constants.TRACKS.ACTIONS.SAVE_PROFILE_SETTINGS_DOCUMENT,
            )
          })
          .catch(err => {
            apiToastWarning(err)
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        apiToastWarning('No file chosen!')
      }
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.cal-icon {
  position: absolute;
  right: 12px;
  bottom: 10px;
  cursor: pointer;
}

.disabled-file-input {
  .custom-file-label {
    cursor: not-allowed !important;
  }

  span {
    color: #757575
  }

  .custom-file-label::after {
    color: #757575
  }
}

.disabled-date-input {
  input, svg {
    background-color: #F5F5F5 !important;
    cursor: not-allowed !important;
  }
}

</style>
